import React, { Suspense } from "react";
import "react-notifications/lib/notifications.css";
import { HelmetProvider } from "react-helmet-async";
const Home = React.lazy(() => import("./routes/home/index"));
import { BrowserRouter, Route, Routes } from "react-router-dom";
const Loader = React.lazy(() => import("./components/Animation/Loader"));
const PageNotFound = React.lazy(() => import("./routes/pageNotFound/index"));
const AppTemplateLayout = React.lazy(() =>
  import("./components/template/AppTemplateLayout/index")
);
const AccessoriesInfo = React.lazy(() =>
  import("./routes/accessories/AccessoriesInfo/AccessoriesInfo")
);
const Accessories = React.lazy(() =>
  import("./routes/accessories/Accessories")
);
const ProductDetail = React.lazy(() => import("./routes/productDetail/index"));
const ControlPanelDetail = React.lazy(() =>
  import("./routes/productDetail/controlPanelDetail/index")
);
const CentralGasInfo = React.lazy(() => import("./routes/centralGases/centralGasInfo"));
import "./App.css";
const CentralGas = React.lazy(() => import("./routes/centralGases"));

const App = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <HelmetProvider>
          <Routes>
            <Route path="/" element={<AppTemplateLayout />}>
              <Route index element={<Home />}></Route>
              <Route path="centralgas" element={<CentralGas />} />
              <Route path="centralgas/:id" element={<CentralGasInfo />} />
              <Route path="accessories" element={<Accessories />} />
              <Route path="accessories/:id" element={<AccessoriesInfo />} />
              <Route
                path={`products/Control-Panel`}
                element={<ControlPanelDetail />}
              />
              <Route
                path={`products/:productKey`}
                element={<ProductDetail />}
              />
            </Route>
            <Route path="*" element={<PageNotFound />}></Route>
          </Routes>
        </HelmetProvider>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
